<template>
	<div ref="scrollRef">
		<H5header :type="2"></H5header>

		<div class="viewbox">
			<div class="newsitem-title" v-html="date.title"></div>
			<div class="source">
				<div class="line">
					<div class="l-1"> 来源: </div>
					<div class="l-2"> {{date.source}} </div>
				</div>
				<div class="line">
					<div class="l-1"> 时间: </div>
					<div class="l-2"> {{date.publishTime}} </div>
				</div>
			</div>
			<div class="content" v-html="date.content"></div>
		</div>

		<!-- <div v-if="date.imgUrl" style="margin: 0 20PX 0 20PX">
			<img :src="date.imgUrl" alt="" style="width: 1200PX;">
		</div> -->
		<div v-if="date.fileUrlList && date.fileUrlList.length > 0" style="margin: 20PX">
			<ul>
				<li v-for="(item,index) in date.fileUrlList" :key="index+'one'" style="margin-bottom: 20PX">
					<div>
						<img src="/images/fujian.png" alt="" style="width: 20PX">
						<a :href="item.url" style="color: #2d52a0">{{item.name}}</a>
					</div>
				</li>
			</ul>
		</div>

		<div class="button">
			<div class="button_name" @click="getupper">【 {{ upper }} 】</div>
			<div class="button_name" @click="getbelow">【 {{ below }} 】</div>
			<!-- <div class="button_name" @click="close">【 关闭 】</div> -->
		</div>

		<div class="left_window">
			<h2 style="padding: 5PX 10PX;">相关信息</h2>
			<ul style="font-size: 14PX; line-height: 28PX;">
				<li class="spantodate1" v-for="(item,index) in recomList" :key="index" @click="jump(item.id)">
					·{{item.title}}</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import Meta from 'vue-meta'
	Vue.use(Meta)
	import wx from 'weixin-js-sdk';
	import H5header from "./components/header2.vue"
	export default {
		name: '',
		components: {
			H5header
		},
		data() {
			return {
				id: '',
				date: {},
				recomList: [],
				upper: '上一篇',
				upperbur: false,
				below: '下一篇',
				belowbur: false,
			}
		},
		created() {
			this.id = this.$route.query.id
			this.getArticleData(this.id)
			if (this.isWeixinBrowser()) {
				this.read()
			}
		},
		methods: {
			getArticleData(id) {
				let data = {
					id: id,
				}
				this.$api.getArticleData(data).then(res => {
					res.data.data.fileUrlList.forEach(item=>{
						if (process.env.VUE_APP_BASE_API == 'https://www.bjcac.org.cn') {
							if (!item.url.startsWith('https')) {
								item.url = 'https' + item.url.slice(4);
							}
						}
					})
					
					this.date = res.data.data
					this.recommend(res.data.data.columnId)
					
					document.title = this.date.title.replace(/<br>/g, ' ') + '-北京市体育竞赛管理和国际交流中心'
					this.$meta().refresh()
					
					this.$getwxsdk(this.date.title.replace(/<br>/g, ' ') + '-北京市体育竞赛管理和国际交流中心')
					
					if (res.data.data.prevArticle == null || res.data.data.prevArticle == undefined || res.data
						.data.prevArticle == '') {
						this.upper = '结束了',
						this.upperbur = true
					} else {
						this.upper = '上一篇',
						this.upperbur = false
					}

					if (res.data.data.nextArticle == null || res.data.data.nextArticle == undefined || res.data
						.data.nextArticle == '') {
						this.below = '结束了',
						this.belowbur = true
					} else {
						this.below = '下一篇',
						this.belowbur = false
					}
				})
			},
			recommend(id) {
				this.$api.recommend(id).then(res => {
					this.recomList = res.data.data
					
					this.recomList.forEach(item=>{
						item.title= item.title.replace(/<br>/g, ' ')
					})
				})
			},
			jump(id) {
				this.getArticleData(id)
				window.scrollTo(0, 0)
			},
			close() {
				window.close()
			},
			getupper() {
				if (this.date.prevArticle == null || this.date.prevArticle == undefined || this.date.prevArticle == '')
					return
				this.getArticleData(this.date.prevArticle.id)
				window.scrollTo(0, 0)
				if (this.isWeixinBrowser()) {
					this.read()
				}
			},
			getbelow() {
				if (this.date.nextArticle == null || this.date.nextArticle == undefined || this.date.nextArticle == '')
					return
				this.getArticleData(this.date.nextArticle.id)
				window.scrollTo(0, 0)
				if (this.isWeixinBrowser()) {
					this.read()
				}
			},
			isWeixinBrowser() {
				let ua = navigator.userAgent.toLowerCase();
				return /micromessenger/.test(ua) ? true : false;
			},
			read() {
				var url = window.location.href
				this.$api.wxShare({url:url}).then(res => {
					if (res.data && res.data.code == 200) {
						//分享数据，这段主要是为了在hash模式下分享出去的链接不被浏览器截取，保证完全把链接分享出去
						//引用
						wx.config({
							debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。（测试记得关掉）
							appId: res.data.data.appid, // 必填，公众号的唯一标识
							timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
							nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
							signature: res.data.data.signature, // 必填，签名，见附录1
							jsApiList: ["updateAppMessageShareData","updateTimelineShareData"] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
						})
						let that = this
						// 设置分享信息
						wx.ready(function() {
							const shareData = {
								title: that.date.title.replace(/<br>/g, ' '),
								link: window.location.href,
								imgUrl: that.date.imgUrl || 'https://www.bjcac.org.cn/upload/2023/08/04/d134036c-f38d-4d2f-bc3d-411c4fc4b971.jpg',
							}

							// 设置分享到朋友圈的信息
							wx.updateTimelineShareData({
								title: shareData.title,
								link: shareData.link,
								imgUrl: shareData.imgUrl,
								desc:shareData.title,
								success: function() {
									console.log('分享到朋友圈成功');
								},
								cancel: function() {
									console.log('取消分享到朋友圈');
								}
							});

							// 设置分享给朋友的信息
							wx.updateAppMessageShareData({
								title: shareData.title,
								link: shareData.link,
								imgUrl: shareData.imgUrl,
								desc:shareData.title,
								success: function() {
									console.log('分享给朋友成功');
								},
								cancel: function() {
									console.log('取消分享给朋友');
								}
							});
						});
					}
				})
			},
			geturl(url){
				if (process.env.VUE_APP_BASE_API == 'https://www.bjcac.org.cn') {
					if (!url.startsWith('https')) {
						url = 'https' + url.slice(4);
					}
					window.open(url);
				} else {
					window.open(url);
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.newsitem-title {
		width: 100%;
		padding: 22PX;
		font-size: 18PX;
		text-align: center;
	}

	.source {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
	}

	.line {
		display: flex;
		align-items: center;
		margin: 0 20PX;
	}

	.l-2 {
		color: #a1a1a1;
		margin-left: 10PX;
	}

	.content {
		margin: 20PX;
	}

	.button {
		margin: 40PX 20PX 0;
		display: flex;
		justify-content: flex-end;
	}

	.button_name {
		cursor: pointer;
	}

	.left_window {
		border: 1PX solid #D4D4D4;
		margin-bottom: 20PX;
		background: none repeat scroll 0 0 #FFFFFF;
		margin-top: 40PX;
		margin: 40PX 20PX 20PX 20PX;
	}

	.left_window h2 {
		line-height: 25PX;
		font-weight: 800;
		color: #111111;
		font-size: 15PX;
		padding: 0PX 10PX;
		background-color: #F5F5F5;
	}

	.left_window ul {
		font-size: 13PX;
		line-height: 25PX;
		margin: 10PX;
	}

	::v-deep iframe {
		width: 100% !important;
	}
</style>